import axios from 'axios'
import { frontendBuildHttpHeader, getAppVersion } from '../appVersion'
import { getApiUrl } from '../backendUrl'

const appVersion = getAppVersion()

export default function configureAxios (token) {
  axios.defaults.baseURL = getApiUrl()
  axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'

  axios.interceptors.request.use(
    config => {
      if (token) {
        config.headers.Authorization = 'Bearer ' + token
      }

      config.headers[frontendBuildHttpHeader] = appVersion

      return config
    },
    error => {
      console.error(error)
      return Promise.reject(error)
    })

  axios.interceptors.response.use(
    response => response,
    error => {
      const response = error.response
      if (!response) {
        console.warn('Did not receive response from server')
      } else if (response.status >= 400 && response.status < 500) {
        console.warn(`Client error: ${response.status} ${response.statusText}`, response.headers, response.data)
      } else if (response.status >= 500) {
        console.error(`Server error: ${response.status} ${response.statusText}`, response.headers, response.data)
      }

      return Promise.reject(error)
    }
  )
}
