import React, { useContext, useEffect } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { AuthContext } from './auth/AuthenticationInternal'
import configureAxios from './client/AxiosClient'

const loading = () => <div className='animated fadeIn pt-3 text-center'>Loading...</div>

const GraphiQLWrapper = React.lazy(() => import('./components/GraphiQlWrapper'))
const CamasUi = React.lazy(() => import('./components/CamasUi'))

const Page404 = React.lazy(() => import('./views/Pages/Page404/Page404'))
const Page500 = React.lazy(() => import('./views/Pages/Page500/Page500'))

const App = () => {
  const authContext = useContext(AuthContext)
  useEffect(() => configureAxios(authContext.authToken), [])

  return (
    <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path='/404' name='Page 404' render={props => <Page404 {...props} />} />
          <Route exact path='/500' name='Page 500' render={props => <Page500 {...props} />} />
          <Route exact path='/graphql' render={() => <GraphiQLWrapper />} />
          <Route path='/' name='Home' render={() => <CamasUi />} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}

export default App
