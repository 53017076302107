const newRelicAgent = window.newrelic

export function setInteractionName (name) {
  if (newRelicAgent) {
    window.newrelic.interaction()
    window.newrelic.setCurrentRouteName(name)
  }
}

export function setReleaseVersion (versionNumber) {
  if (newRelicAgent) {
    newRelicAgent.addRelease('camas', versionNumber)
  }
}
