/* eslint-env browser */
import React from 'react'
import ReactDOM from 'react-dom'
import { getAppVersion } from './appVersion'
import App from './App'
import Authentication from './auth/Authentication'
import * as NewRelic from './newRelicAgent'

const appVersion = getAppVersion()

console.log(`Camas version ${appVersion}`)
NewRelic.setReleaseVersion(appVersion)

ReactDOM.render(
  <Authentication>
    <App />
  </Authentication>
  , document.getElementById('root'))
