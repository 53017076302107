import React, { useState, useEffect, FC } from 'react'
import { centralizedAuth as CentralizedAuth } from '@cimpress/simple-auth-wrapper'
import { getClientId } from '../backendUrl'

const auth = new CentralizedAuth({
  clientID: getClientId(),
  redirectUri: window.location.origin,
  emitInitialTokenExpired: false
})

auth.on('tokenExpired', () => auth.login({ nextUri: window.location.pathname, forceLogin: true }))

const AuthenticationInternal: FC = ({ children }) => {
  const [authenticationError, setAuthenticationError] = useState<Error | null>(null)
  const [authDetails, setAuthDetails] = useState<AuthenticationDetails | null>(null)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(auth.isLoggedIn())

  useEffect(() => {
    auth
      .ensureAuthentication({ nextUri: window.location.pathname + window.location.search })
      .then((result: any) => {
        setIsLoggedIn(result)
      })
      .catch((err: Error) => {
        setAuthenticationError(err)
      })
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      setAuthDetails({
        userProfile: auth.getProfile(),
        authToken: auth.getAccessToken()
      })
    }
  }, [isLoggedIn])

  if (authDetails) {
    return (
      <AuthContext.Provider value={authDetails}>
        {children}
      </AuthContext.Provider>
    )
  }

  if (authenticationError) {
    return (
      <p>
        Error trying to log you in:
        <code>{authenticationError.message}</code>
      </p>
    )
  }

  return (
    <p>
      Logging you in...
    </p>
  )
}

export interface AuthenticationDetails {
  userProfile: {
    name: string,
    picture: string,
    email: string
  },
  authToken: string
}

export const AuthContext = React.createContext<AuthenticationDetails | null>(null)

export default AuthenticationInternal
