/* eslint-env browser */
const stgBackendUri = 'https://camasb.dev.ct.vpsvc.com'

type BackendEnvironment = { name: string, uri: string }

export type EnvironmentType = 'PROD' | 'STG' | 'TST' | 'DEV'
type Environment = {
  name: string,
  environmentType: EnvironmentType,
  clientId: string,
  backend: BackendEnvironment
}

const clientIdProd = process.env.REACT_APP_CLIENT_ID_PROD || ''
const clientIdStg = process.env.REACT_APP_CLIENT_ID_STG || ''

const environments: { PROD: Environment; TST: Environment; STG: Environment; Local: Environment } = {
  PROD: {
    name: 'Prod',
    environmentType: 'PROD',
    clientId: clientIdProd,
    backend: {
      name: 'Prod',
      uri: 'https://camasb.ct.vpsvc.com'
    }
  },
  STG: {
    name: 'Stg',
    environmentType: 'STG',
    clientId: clientIdStg,
    backend: {
      name: 'Stg',
      uri: stgBackendUri
    }
  },
  TST: {
    name: 'Test',
    environmentType: 'TST',
    clientId: clientIdStg,
    backend: {
      name: 'Stg',
      uri: stgBackendUri
    }
  },
  Local: {
    name: 'Local',
    environmentType: 'DEV',
    clientId: clientIdStg,
    backend: {
      name: 'Local',
      uri: 'http://localhost:5000'
    }
  }
}

function getHostName (): string {
  if (!window || !window.location || !window.location.protocol || !window.location.hostname) {
    throw new Error('window global property not found. Not running inside a browser?')
  }

  return window.location.hostname
}

function getBackendUrl (): string {
  const environment = getEnvironment()
  return environment.backend.uri
}

export function getGraphQlUrl (): string {
  const uri = new URL('/graphql', getBackendUrl())
  return uri.toString()
}

export function getApiUrl (): string {
  const uri = new URL('/api', getBackendUrl())
  return uri.toString()
}

export function getClientId (): string {
  const environment = getEnvironment()
  return environment.clientId
}

export function getEnvironment (): Environment {
  const hostname = getHostName()

  switch(hostname) {
    case 'localhost': return environments.Local
    case 'camas.ct.vpsvc.com': return environments.PROD
    case 'camas.dev.ct.vpsvc.com': return environments.STG
    case 'camas-test.dev.ct.vpsvc.com': return environments.TST
    default: throw new Error('Unknown environment')
  }
}
