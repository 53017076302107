import React, { FC } from 'react'
import AuthenticationInternal from './AuthenticationInternal'
import AuthenticationDummy from './AuthenticationDummy'

const Authentication: FC = ({ children }) => {
  // @ts-ignore
  const runningInTestMode = !!window.Cypress
  const AuthenticationComponent = runningInTestMode ? AuthenticationDummy : AuthenticationInternal

  return (
    <AuthenticationComponent>
      {children}
    </AuthenticationComponent>
  )
}

export default Authentication
