import React, { FC } from 'react'
import { AuthContext, AuthenticationDetails } from './AuthenticationInternal'

const AuthenticationDummy: FC = ({ children }) => {
  const authDetails: AuthenticationDetails = {
    authToken: 'dummyToken',
    userProfile: {
      name: 'test user',
      email: '',
      picture: ''
    }
  }
  return (
    <AuthContext.Provider value={authDetails}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthenticationDummy
